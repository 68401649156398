import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import logo from "../assets/OnefiLogoVector.svg";
import { Link as ScrollLink } from "react-scroll";
import { Link as RouterLink } from "react-router-dom"; // For routing to other pages

const NavBar = () => {
  const [nav, setNav] = useState(false);

  const links = [
    { id: 1, link: "Home" },
    { id: 2, link: "Whitepaper", url: "/Whitepaper" },
    { id: 3, link: "Features" },
    { id: 4, link: "Token" },
    { id: 5, link: "Share and Earn" },
    { id: 6, link: "How It Works" },
    { id: 7, link: "News" },
  ];

  return (
    <div
      className="flex justify-between items-center h-20 text-white bg-black fixed object-contain px-4 w-screen"
    >
      <div>
        <RouterLink to="/" id="logo" alt="XMesh - go to homepage">
          <img src={logo} alt="XMesh Logo" className="object-contain" />
        </RouterLink>
      </div>

      <ul className="hidden md:flex landscape:flex">
        {links.map(({ id, link, url }) => (
          <li
            key={id}
            className="cursor-pointer md:px-4 px-1 capitalize font-medium md:text-base text-xs text-gray-500 hover:scale-105 duration-200"
          >
            {url ? (
              <RouterLink to={url} smooth duration={500}>
                {link}
              </RouterLink>
            ) : (
              <ScrollLink to={link} smooth duration={500}>
                {link}
              </ScrollLink>
            )}
          </li>
        ))}
      </ul>

      {/* Mobile Menu Icon */}
      <div
        onClick={() => setNav(!nav)}
        className="cursor-pointer z-40 text-gray-500 md:hidden landscape:hidden"
      >
        {nav ? (
          <FaTimes size={30} className="object-contain" />
        ) : (
          <FaBars size={30} className="object-contain" />
        )}
      </div>

      {/* Mobile Menu */}
      {nav && (
        <ul className="flex flex-col justify-center items-center w-screen absolute top-0 right-0 h-screen bg-gradient-to-b from-black to-gray-800 text-gray-500 z-30 outline outline-4">
          {links.map(({ id, link, url }) => (
            <li
              key={id}
              className="cursor-pointer capitalize py-2 text-2xl z-11"
            >
              {url ? (
                <RouterLink
                  onClick={() => setNav(!nav)}
                  to={url}
                  smooth
                  duration={500}
                >
                  {link}
                </RouterLink>
              ) : (
                <ScrollLink
                  onClick={() => setNav(!nav)}
                  to={link}
                  smooth
                  duration={500}
                >
                  {link}
                </ScrollLink>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default NavBar;
